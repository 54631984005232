<template>
  <div class="contents">
    <!-- Error page -->
    <div class="error-page">
      <div class="headline text-red">404</div>
      <div class="error-content">
        <h3><i class="fa fa-warning text-red"></i> 요청하신 페이지를 찾을 수 없습니다.</h3>
        <p>
          페이지의 주소가 잘못 입력되었거나 주소가 변경 혹은 삭제되었을 수 있습니다. <br/>
          입력하신 주소가 정확한지 다시 한 번 확인해 주시길 바랍니다. <br/>
          관련 문의사항을 관리자에게 문의하시면 친절하게 안내해 드리겠습니다. <br/>
          이용에 불편을 드려 죄송합니다.
        </p>
      </div>
    </div>
  </div>
</template>
<script>

export default {
	name: 'PageErrorPageNotFound404',
}
</script>